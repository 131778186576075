$grid-breakpoints: (
    xs: 0,
    sm: 480px,
    md: 768px,
    lg: 1025px,
    xl: 1280px,
    xxl: 1432px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1432px,
    xxl: 1433px,
);

$grid-gutter-width: 20px;
$font-family-base: 'Roboto', Arial, Helvetica, sans-serif;
$enable-responsive-font-sizes: true;
$theme-colors: (
    'primary': #00599c,
    'red': #c8102e,
    'secondary': #424242,
    'success': #008000,
    'info': #00599c,
    'warning': #ff9933,
    'danger': #c8102e,
    'light': #d6d6d6,
    'dark': #343a40,
);

$tooltip-bg: #333333;
$tooltip-opacity: 0.9;

$link-color: #00599c;
$link-hover-color: #00599c;

$border-radius: 2px;
$border-radius-lg: 0.2rem;
$border-color: #d6d6d6;
$card-border-color: #d6d6d6;
$gray-300: #d6d6d6;
$headings-font-weight: 400;
$spacer: 1rem !default;
$spacers: () !default;
$spacers: (
    5: (
        $spacer * 2,
    ),
    6: (
        $spacer * 2.5,
    ),
    7: (
        $spacer * 3,
    ),
    8: (
        $spacer * 3.5,
    ),
    9: (
        $spacer * 4,
    ),
    10: (
        $spacer * 4.5,
    ),
);
$modal-inner-padding: 2rem;
$zindex-tooltip: 1270 !default;

$input-focus-border-color: #00599c;
$input-disabled-bg: #f5f5f5;