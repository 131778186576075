$gray-100: #f5f5f5;
$gray-150: #e5e5e5;
$gray-200: #d6d6d6;
$gray-250: #c7c7c7;
$gray-300: #b7b7b7;
$gray-350: #a8a8a8;
$gray-400: #767676;
$gray-450: #707070;
$gray-500: #606060;
$gray-550: #515151;
$gray-600: #424242;
$gray-650: #333333;
$gray-700: #232323;
$gray-900: #000000;

$red-50: #fdebed;
$red-400: #ea132b;
$red-500: #c8102e;
$red-600: #b20f21;
$red-800: #790a16;

$green-100: #5daf5d;
$green-200: #008000;
$yellow-100: #ffeebf;
$yellow-200: #997942;
$yellow-300: #ffff00;
$blue-100: #2d91b7;
$blue-150: #2196f3;
$blue-200: #00599c;

$orange: #ff9933;
$black: #000000;
$white: #ffffff;

$colors: (
    ('gray-100', $gray-100),
    ('gray-150', $gray-150),
    ('gray-200', $gray-200),
    ('gray-250', $gray-250),
    ('gray-300', $gray-300),
    ('gray-350', $gray-350),
    ('gray-400', $gray-400),
    ('gray-450', $gray-450),
    ('gray-500', $gray-500),
    ('gray-550', $gray-550),
    ('gray-600', $gray-600),
    ('gray-650', $gray-650),
    ('gray-700', $gray-700),
    ('gray-900', $gray-900),
    ('red-50', $red-50),
    ('red-400', $red-400),
    ('red-500', $red-500),
    ('red-600', $red-600),
    ('red-800', $red-800),
    ('green-200', $green-200),
    ('yellow-100', $yellow-100),
    ('yellow-200', $yellow-200),
    ('yellow-300', $yellow-300),
    ('orange', $orange),
    ('blue-100', $blue-100),
    ('blue-150', $blue-150),
    ('blue-200', $blue-200),
    ('black', $black),
    ('white', $white)
);

$font-family: 'Roboto', Arial, Helvetica, sans-serif;
$inputHeight: 32px;
$borders: 1px solid $gray-200 !default;
