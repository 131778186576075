@import './variables';

@each $color in $colors {
    .#{nth($color, 1)} {
        color: nth($color, 2);

        p {
            color: nth($color, 2);
        }
    }
}

@each $color in $colors {
    .#{nth($color, 1)}-bg {
        background-color: nth($color, 2);
    }
}
