@import '../../base/variables';

.input-group {
    flex-wrap: nowrap;

    .form-control-lg {
        flex: 1 1 auto !important;
        padding: 24px 0 12px;
        border-radius: 0;
        height: 52px;
        font-size: 1rem;
        text-indent: 8px;
    }
    [data-binding='clearText'] {
        cursor: pointer;
    }

    i.error, .svg-inline--fa.error {
        right: 32px;
    }
    .floating {
        margin-bottom: 0;
    }
}

.qty {
    width: 55px;
    height: 38px;
    position: relative;
    top: 0;
    text-align: center;

    &.hero {
        width: 50px;
        height: 50px;
        font-size: 16px;
        text-align: center;
    }
}

.floating-filter {
    height: 52px;

    .floating {
        width: 100%;
    }

    .form-control {
        height: 52px;
        padding-top: 24px;
    }
}

.floating {
    position: relative;
    margin-bottom: 1.5rem;

    input,
    select {
        position: relative;
        height: 52px;
        padding-top: 24px;
        padding-left: 12px;
        -webkit-appearance: none;
        z-index: 2;

        &.error {
            border-color: $red-500;
            display: inline-block;
            border: 2px solid $red-500 !important;
        }

        &.error:focus {
            box-shadow: 0 0 0 2px lighten(#c8102e, 25%);
        }
    }

    textarea {
        position: relative;
        height: 100px;
        padding-top: 24px;
        padding-left: 12px;
        resize: none;

        &.error {
            border-color: #c8102e;
            border-width: 2px;
            display: inline-block;
        }

        &.error:focus {
            box-shadow: 0 0 0 2px lighten(#c8102e, 25%);
        }
    }

    i.dedent, .svg-inline--fa.dedent {
        right: 32px !important;
    }

    input:not(.error) ~ i.error,
    select:not(.error) ~ i.error,
    textarea:not(.error) ~ i.error {
        display: none;
    }

    i.error, .svg-inline--fa.error {
        margin-left: 8px;
        color: #c8102e;
        margin-top: 16px;
        font-size: 22px;
        display: inline-block;
        vertical-align: top;
        top: 0;
        right: 13px;
        position: absolute;
        z-index: 200;
    }

    i.action.errorState, .svg-inline--fa.action.errorState {
        right: 48px !important;
        font-size: 18px;
    }

    input:not(.error) ~ i.action.errorState, input:not(.error) ~ .svg-inline--fa.action.errorState {
        right: 12px !important;
    }

    span.error {
        text-align: left;
        font-size: 14px;
    }

    input ~ label:first-of-type {
        position: absolute;
        top: 15px;
        left: 12px;
        transition: all 0.3s;
        color: #767676;
        font-size: 16px;
        z-index: 10;
    }

    input:focus ~ label:first-of-type,
    input:focus ~ label:first-of-type,
    input.float ~ label,
    input.valid ~ label {
        top: 4px;
        left: 13px;
        font-size: 14px;
        color: #767676;

        &.error {
            color: #c8102e;
            display: block;
            font-size: 14px;
            text-align: left;
            top: 48px;
            left: 0;
        }
    }

    select ~ label:first-of-type {
        position: absolute;
        top: 15px;
        left: 12px;
        transition: all 0.3s;
        color: #767676;
        font-size: 16px;
        z-index: 2;
    }

    select:not([value='']):valid ~ label:first-of-type,
    select:not([value='']).disabled ~ label:first-of-type,
    select.float ~ label {
        position: absolute;
        top: 4px;
        left: 13px;
        font-size: 14px;
        color: #767676;
        z-index: 20;
    }

    textarea ~ label:first-of-type {
        position: absolute;
        top: 15px;
        left: 12px;
        transition: all 0.3s;
        color: #767676;
        font-size: 16px;
    }

    textarea:focus ~ label:first-of-type,
    textarea:focus ~ label:first-of-type,
    textarea.float ~ label {
        top: 4px;
        left: 13px;
        font-size: 14px;
        color: #767676;

        &.error {
            color: #c8102e;
            display: block;
            font-size: 14px;
            text-align: left;
            top: 48px;
            left: 0;
        }
    }

    label.error {
        color: #c8102e;
        display: block;
        font-size: 14px;
        text-align: left;
        top: 48px;
        left: 0;
        margin-bottom: -21px;
        margin-top: 0;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus,
    select:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
}

.required {
    position: relative;
    margin-bottom: 1.5rem;

    i.error, .svg-inline--fa.error {
        margin-left: 8px;
        color: #c8102e;
        margin-top: 18px;
        font-size: 22px;
        display: inline-block;
        vertical-align: top;
        top: 0;
        right: 13px;
        position: absolute;
        z-index: 200;
    }

    textarea {
        position: relative;
        height: 100px;
        padding-left: 12px;
        resize: none;

        &.error {
            border-color: #c8102e;
            border-width: 2px;
            display: inline-block;
        }

        &.error:focus {
            box-shadow: 0 0 0 2px lighten(#c8102e, 25%);
        }
    }

    textarea:not(.error) ~ i.error {
        display: none;
    }

    label.error {
        font-size: 14px;
        margin-top: -4px;
    }
}

.custom-file {
    height: 52px;

    .custom-file-label {
        line-height: 40px;
        height: 52px;
        overflow: hidden;
    }

    .custom-file-input {
        &.error ~ label {
            border-color: $red-500;
            border-width: 2px;
            display: inline-block;

            &::after {
                height: 48px;
            }
        }
    }

    .custom-file-input,
    .custom-file-input ~ .custom-file-label::after {
        line-height: 40px;
        height: 50px;
    }

    label.error {
        color: $red-500;
        display: block;
        font-size: 14px;
        text-align: left;
    }
}

// Create input icon
.imp-input-group {
    position: relative;
    font-weight: normal;

    input {
        width: 100%;
        border: $borders;
        text-indent: 12px;
    }

    i, .svg-inline--fa {
        position: absolute;
        top: 0;
        font-size: 18px;
        color: $gray-200;
        right: 0;
        z-index: 11;
    }

    i.action, .svg-inline--fa.action {
        color: $gray-550;
        cursor: pointer;
        padding: 16px;
    }

    input:first-child {
        text-indent: 0;
    }

    input:last-child {
        text-indent: 22px;
    }

    i:first-child, .svg-inline--fa:first-child {
        left: 5px;
    }

    i:last-child, .svg-inline--fa:last-child {
        right: 16px;
    }
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

.input-group-append:not(:last-child) > .btn {
    border-top-right-radius: 2px !important;
    border-bottom-right-radius: 2px !important;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}

input:focus {
    border-color: inherit;
}

.custom-select {
    -webkit-appearance: none;
}
